.btn-blue-50 {
    @apply bg-blue-50 hover:bg-blue-100 text-black;
}

.btn-blue-100 {
    @apply bg-blue-100 hover:bg-blue-200 text-black;
}

.btn-blue-200 {
    @apply bg-blue-200 hover:bg-blue-300 text-black;
}

.btn-blue-300 {
    @apply bg-blue-300 hover:bg-blue-400 text-black;
}

.btn-blue-400 {
    @apply bg-blue-400 hover:bg-blue-500 text-black;
}

.btn-blue-500 {
    @apply bg-blue-500 hover:bg-blue-400 text-black;
}

.btn-blue-600 {
    @apply bg-blue-600 hover:bg-blue-400 text-white;
}

.btn-blue-700 {
    @apply bg-blue-700 hover:bg-blue-500 text-white;
}

.btn-blue-800 {
    @apply bg-blue-800 hover:bg-blue-500 text-white;
}

.btn-blue-900 {
    @apply bg-blue-900 hover:bg-blue-600 text-white;
}

.btn-blue-950 {
    @apply bg-blue-950 hover:bg-blue-600 text-white;
}

.btn-gray-50 {
    @apply bg-gray-50 hover:bg-gray-100 text-black;
}

.btn-gray-100 {
    @apply bg-gray-100 hover:bg-gray-200 text-black;
}

.btn-gray-200 {
    @apply bg-gray-200 hover:bg-gray-300 text-black;
}

.btn-gray-300 {
    @apply bg-gray-300 hover:bg-gray-400 text-black;
}

.btn-gray-400 {
    @apply bg-gray-400 hover:bg-gray-500 text-black;
}

.btn-gray-500 {
    @apply bg-gray-500 hover:bg-gray-400 text-black;
}

.btn-gray-600 {
    @apply bg-gray-600 hover:bg-gray-400 text-white;
}

.btn-gray-700 {
    @apply bg-gray-700 hover:bg-gray-500 text-white;
}

.btn-gray-800 {
    @apply bg-gray-800 hover:bg-gray-500 text-white;
}

.btn-gray-900 {
    @apply bg-gray-900 hover:bg-gray-600 text-white;
}

.btn-gray-950 {
    @apply bg-gray-950 hover:bg-gray-600 text-white;
}

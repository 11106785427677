.btn-slate-50 {
    @apply bg-slate-50 hover:bg-slate-100 text-black;
}

.btn-slate-100 {
    @apply bg-slate-100 hover:bg-slate-200 text-black;
}

.btn-slate-200 {
    @apply bg-slate-200 hover:bg-slate-300 text-black;
}

.btn-slate-300 {
    @apply bg-slate-300 hover:bg-slate-400 text-black;
}

.btn-slate-400 {
    @apply bg-slate-400 hover:bg-slate-500 text-black;
}

.btn-slate-500 {
    @apply bg-slate-500 hover:bg-slate-400 text-black;
}

.btn-slate-600 {
    @apply bg-slate-600 hover:bg-slate-400 text-white;
}

.btn-slate-700 {
    @apply bg-slate-700 hover:bg-slate-500 text-white;
}

.btn-slate-800 {
    @apply bg-slate-800 hover:bg-slate-500 text-white;
}

.btn-slate-900 {
    @apply bg-slate-900 hover:bg-slate-600 text-white;
}

.btn-slate-950 {
    @apply bg-slate-950 hover:bg-slate-600 text-white;
}

.btn-cyan-50 {
    @apply bg-cyan-50 hover:bg-cyan-100 text-black;
}

.btn-cyan-100 {
    @apply bg-cyan-100 hover:bg-cyan-200 text-black;
}

.btn-cyan-200 {
    @apply bg-cyan-200 hover:bg-cyan-300 text-black;
}

.btn-cyan-300 {
    @apply bg-cyan-300 hover:bg-cyan-400 text-black;
}

.btn-cyan-400 {
    @apply bg-cyan-400 hover:bg-cyan-500 text-black;
}

.btn-cyan-500 {
    @apply bg-cyan-500 hover:bg-cyan-400 text-black;
}

.btn-cyan-600 {
    @apply bg-cyan-600 hover:bg-cyan-400 text-white;
}

.btn-cyan-700 {
    @apply bg-cyan-700 hover:bg-cyan-500 text-white;
}

.btn-cyan-800 {
    @apply bg-cyan-800 hover:bg-cyan-500 text-white;
}

.btn-cyan-900 {
    @apply bg-cyan-900 hover:bg-cyan-600 text-white;
}

.btn-cyan-950 {
    @apply bg-cyan-950 hover:bg-cyan-600 text-white;
}

.btn-sky-50 {
    @apply bg-sky-50 hover:bg-sky-100 text-black;
}

.btn-sky-100 {
    @apply bg-sky-100 hover:bg-sky-200 text-black;
}

.btn-sky-200 {
    @apply bg-sky-200 hover:bg-sky-300 text-black;
}

.btn-sky-300 {
    @apply bg-sky-300 hover:bg-sky-400 text-black;
}

.btn-sky-400 {
    @apply bg-sky-400 hover:bg-sky-500 text-black;
}

.btn-sky-500 {
    @apply bg-sky-500 hover:bg-sky-400 text-black;
}

.btn-sky-600 {
    @apply bg-sky-600 hover:bg-sky-400 text-white;
}

.btn-sky-700 {
    @apply bg-sky-700 hover:bg-sky-500 text-white;
}

.btn-sky-800 {
    @apply bg-sky-800 hover:bg-sky-500 text-white;
}

.btn-sky-900 {
    @apply bg-sky-900 hover:bg-sky-600 text-white;
}

.btn-sky-950 {
    @apply bg-sky-950 hover:bg-sky-600 text-white;
}

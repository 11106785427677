.btn-orange-50 {
    @apply bg-orange-50 hover:bg-orange-100 text-black;
}

.btn-orange-100 {
    @apply bg-orange-100 hover:bg-orange-200 text-black;
}

.btn-orange-200 {
    @apply bg-orange-200 hover:bg-orange-300 text-black;
}

.btn-orange-300 {
    @apply bg-orange-300 hover:bg-orange-400 text-black;
}

.btn-orange-400 {
    @apply bg-orange-400 hover:bg-orange-500 text-black;
}

.btn-orange-500 {
    @apply bg-orange-500 hover:bg-orange-400 text-black;
}

.btn-orange-600 {
    @apply bg-orange-600 hover:bg-orange-400 text-white;
}

.btn-orange-700 {
    @apply bg-orange-700 hover:bg-orange-500 text-white;
}

.btn-orange-800 {
    @apply bg-orange-800 hover:bg-orange-500 text-white;
}

.btn-orange-900 {
    @apply bg-orange-900 hover:bg-orange-600 text-white;
}

.btn-orange-950 {
    @apply bg-orange-950 hover:bg-orange-600 text-white;
}

@import "./assets/css/buttons.css";
@import "./assets/css/nav.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
    box-sizing: border-box;
}

html,
body {
    margin: 0;
    padding: 0;
}

#root,
.App {
    width: 100%;
    height: 100%;
}

.h-screen2 {
    height: 200vh;
}

.center {
    @apply flex items-center justify-center;
}

.error-message {
    @apply text-red-500;
}

/*
::-webkit-scrollbar {
    display: none;
}

.element-class::-webkit-scrollbar {
    display: none;
}
*/

.button-1::before {
    @apply bg-violet-600;
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    z-index: -2;
    transform-origin: left top;
    animation: rotate 1s linear infinite;
}

.button-1::after {
    @apply bg-blue-100;
    content: "";
    position: absolute;
    top: 3px;
    left: 3px;
    width: calc(100% - 2 * 3px);
    height: calc(100% - 2 * 3px);
    border-radius: inherit;
    z-index: -1;
}

@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

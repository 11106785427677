.btn-lime-50 {
    @apply bg-lime-50 hover:bg-lime-100 text-black;
}

.btn-lime-100 {
    @apply bg-lime-100 hover:bg-lime-200 text-black;
}

.btn-lime-200 {
    @apply bg-lime-200 hover:bg-lime-300 text-black;
}

.btn-lime-300 {
    @apply bg-lime-300 hover:bg-lime-400 text-black;
}

.btn-lime-400 {
    @apply bg-lime-400 hover:bg-lime-500 text-black;
}

.btn-lime-500 {
    @apply bg-lime-500 hover:bg-lime-400 text-black;
}

.btn-lime-600 {
    @apply bg-lime-600 hover:bg-lime-400 text-white;
}

.btn-lime-700 {
    @apply bg-lime-700 hover:bg-lime-500 text-white;
}

.btn-lime-800 {
    @apply bg-lime-800 hover:bg-lime-500 text-white;
}

.btn-lime-900 {
    @apply bg-lime-900 hover:bg-lime-600 text-white;
}

.btn-lime-950 {
    @apply bg-lime-950 hover:bg-lime-600 text-white;
}

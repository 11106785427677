.btn-yellow-50 {
    @apply bg-yellow-50 hover:bg-yellow-100 text-black;
}

.btn-yellow-100 {
    @apply bg-yellow-100 hover:bg-yellow-200 text-black;
}

.btn-yellow-200 {
    @apply bg-yellow-200 hover:bg-yellow-300 text-black;
}

.btn-yellow-300 {
    @apply bg-yellow-300 hover:bg-yellow-400 text-black;
}

.btn-yellow-400 {
    @apply bg-yellow-400 hover:bg-yellow-500 text-black;
}

.btn-yellow-500 {
    @apply bg-yellow-500 hover:bg-yellow-400 text-black;
}

.btn-yellow-600 {
    @apply bg-yellow-600 hover:bg-yellow-400 text-white;
}

.btn-yellow-700 {
    @apply bg-yellow-700 hover:bg-yellow-500 text-white;
}

.btn-yellow-800 {
    @apply bg-yellow-800 hover:bg-yellow-500 text-white;
}

.btn-yellow-900 {
    @apply bg-yellow-900 hover:bg-yellow-600 text-white;
}

.btn-yellow-950 {
    @apply bg-yellow-950 hover:bg-yellow-600 text-white;
}

.btn-purple-50 {
    @apply bg-purple-50 hover:bg-purple-100 text-black;
}

.btn-purple-100 {
    @apply bg-purple-100 hover:bg-purple-200 text-black;
}

.btn-purple-200 {
    @apply bg-purple-200 hover:bg-purple-300 text-black;
}

.btn-purple-300 {
    @apply bg-purple-300 hover:bg-purple-400 text-black;
}

.btn-purple-400 {
    @apply bg-purple-400 hover:bg-purple-500 text-black;
}

.btn-purple-500 {
    @apply bg-purple-500 hover:bg-purple-400 text-black;
}

.btn-purple-600 {
    @apply bg-purple-600 hover:bg-purple-400 text-white;
}

.btn-purple-700 {
    @apply bg-purple-700 hover:bg-purple-500 text-white;
}

.btn-purple-800 {
    @apply bg-purple-800 hover:bg-purple-500 text-white;
}

.btn-purple-900 {
    @apply bg-purple-900 hover:bg-purple-600 text-white;
}

.btn-purple-950 {
    @apply bg-purple-950 hover:bg-purple-600 text-white;
}

.btn-red-50 {
    @apply bg-red-50 hover:bg-red-100 text-black;
}

.btn-red-100 {
    @apply bg-red-100 hover:bg-red-200 text-black;
}

.btn-red-200 {
    @apply bg-red-200 hover:bg-red-300 text-black;
}

.btn-red-300 {
    @apply bg-red-300 hover:bg-red-400 text-black;
}

.btn-red-400 {
    @apply bg-red-400 hover:bg-red-500 text-black;
}

.btn-red-500 {
    @apply bg-red-500 hover:bg-red-400 text-black;
}

.btn-red-600 {
    @apply bg-red-600 hover:bg-red-400 text-white;
}

.btn-red-700 {
    @apply bg-red-700 hover:bg-red-500 text-white;
}

.btn-red-800 {
    @apply bg-red-800 hover:bg-red-500 text-white;
}

.btn-red-900 {
    @apply bg-red-900 hover:bg-red-600 text-white;
}

.btn-red-950 {
    @apply bg-red-950 hover:bg-red-600 text-white;
}

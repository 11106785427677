.btn-stone-50 {
    @apply bg-stone-50 hover:bg-stone-100 text-black;
}

.btn-stone-100 {
    @apply bg-stone-100 hover:bg-stone-200 text-black;
}

.btn-stone-200 {
    @apply bg-stone-200 hover:bg-stone-300 text-black;
}

.btn-stone-300 {
    @apply bg-stone-300 hover:bg-stone-400 text-black;
}

.btn-stone-400 {
    @apply bg-stone-400 hover:bg-stone-500 text-black;
}

.btn-stone-500 {
    @apply bg-stone-500 hover:bg-stone-400 text-black;
}

.btn-stone-600 {
    @apply bg-stone-600 hover:bg-stone-400 text-white;
}

.btn-stone-700 {
    @apply bg-stone-700 hover:bg-stone-500 text-white;
}

.btn-stone-800 {
    @apply bg-stone-800 hover:bg-stone-500 text-white;
}

.btn-stone-900 {
    @apply bg-stone-900 hover:bg-stone-600 text-white;
}

.btn-stone-950 {
    @apply bg-stone-950 hover:bg-stone-600 text-white;
}
